import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor() {}

  menuUpdate() {
    $('.itemMenu').removeClass('active');
    switch (location.hash) {
      case '#/user/dashboard':
        $(`#dashboard`).addClass('active');
        break;
      case '#/user/profile':
        $(`#dados`).addClass('active');
        break;
      case '#/user/your-data':
        $(`#dados`).addClass('active');
        break;
      case '#/user/co-participation':
        $(`#coparticipacao`).addClass('active');
        break;
      case '#/user/contract':
        $(`#contrato`).addClass('active');
        break;
      case '#/user/financial':
        $(`#financeiro`).addClass('active');
        break;
      case '#/user/accredited-networks':
        $(`#redes`).addClass('active');
        break;
      case '#/user/notifications':
        $(`#notificacoes`).addClass('active');
        break;
      default:
        break;
    }
  }
}
